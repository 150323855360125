import {
    doc,
    getDocs,
    getDoc,
    setDoc,
    updateDoc,
    collection,
    query,
    where,
} from 'firebase/firestore'
import {
    getAuth,
    sendPasswordResetEmail,
    updateEmail,
    updatePassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    reauthenticateWithCredential,
    signOut,
    EmailAuthProvider,
} from 'firebase/auth'
import { db, myFirebase } from '../system/firebase/index'
import {
    requestLogin,
    loginError,
    requestLogout,
    receiveLogout,
    logoutError,
    verifyRequest,
    setAuthUser,
    receiveLogin,
    verifySuccess,
    passwordMismatch,
} from '../system/redux/reducers/auth'
import { navigate } from 'gatsby'

export const getUsers = async (id, whereArray = []) => {
    if (Array.isArray(whereArray) && whereArray.length) {
        const q = query(
            collection(db, 'users'),
            where(whereArray[0], whereArray[1], whereArray[2]),
        )
        const queryWhereSnapshot = await getDocs(q)

        return queryWhereSnapshot
    }
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
    return docSnap
}
export const createUser = (email, password, details) => {
    const auth = getAuth()
    const originalUser = auth.currentUser
    createUserWithEmailAndPassword(auth, email, password)
        .then((cred) => {
            const userRef = doc(db, 'users', cred.user.uid)
            details.uid = cred.user.uid
            details.email = email
            setDoc(userRef, details).then(() => {
                auth.updateCurrentUser(originalUser) // Remains on admin account.
            })
        })
        .then(() => {
            sendPasswordResetEmail(auth, email)
        })
}

export const loginUser = (dispatch, email, password) => {
    dispatch(requestLogin())
    const auth = getAuth(myFirebase)
    signInWithEmailAndPassword(auth, email, password)
        .then((data) => {
            verifyAuth(dispatch)
            navigate(`/user`)
        })
        .catch((error) => {
            // console.log(error)
            // Do something with the error if you want!
            dispatch(loginError())
        })
}
export const updateUserEmail = (dispatch, email, password) => {
    const auth = getAuth()
    const user = auth.currentUser
    const credential = EmailAuthProvider.credential(user.email, password)
    reauthenticateWithCredential(user, credential)
        .then(() => {
            updateEmail(user, email)
                .then(
                    () => {
                        const userRef = doc(db, 'users', user.uid)
                        const uploadData = {
                            contact: {
                                email: email,
                            },
                        }
                        updateDoc(userRef, uploadData)
                    },
                    dispatch(passwordMismatch(false)),
                    // console.log('Reauthentication successfull, update email successful.'),
                )
                .catch((error) => {
                    // console.log('Reauthentication successfull, error updating email.')
                    // console.log(error)
                })
        })
        .catch((error) => {
            // console.log('Error reauthenticating')
            // console.log(error)
            dispatch(passwordMismatch(true))
        })
}
export const updateUserPassword = (dispatch, password, newPassword) => {
    const auth = getAuth()
    const user = auth.currentUser
    const credential = EmailAuthProvider.credential(user.email, password)
    reauthenticateWithCredential(user, credential)
        .then(() => {
            updatePassword(user, newPassword)
                .then(() => {
                    dispatch(passwordMismatch(false))
                })
                .catch((error) => {
                    // console.log(error)
                })
        })
        .catch((error) => {
            // console.log(error)
            dispatch(passwordMismatch(true))
        })
}
export const resetUserPassword = (email) => {
    const auth = getAuth()
    sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log('Email sent successfully')
        })
        .catch((error) => {
            console.log('Email failed with error:', error.message)
        })
}

export const logoutUser = (dispatch) => {
    dispatch(requestLogout())
    const auth = getAuth(myFirebase)
    signOut(auth)
        .then(() => {
            dispatch(receiveLogout())
        })
        .catch((error) => {
            // Do something with the error if you want!
            dispatch(logoutError())
        })
}

export const verifyAuth = (dispatch) => {
    dispatch(verifyRequest())
    const auth = getAuth(myFirebase)
    onAuthStateChanged(auth, (user) => {
        if (user !== null) {
            const docRef = doc(db, 'users', user.uid)
            getDoc(docRef).then((doc) => {
                if (doc.exists()) {
                    const userData = JSON.stringify(doc.data())
                    const userSerialized = JSON.stringify(user)
                    dispatch(setAuthUser(userData))
                    dispatch(receiveLogin(userSerialized))
                    dispatch(verifySuccess())
                } else {
                    dispatch(loginError())
                }
            })
        } else {
            navigate(`/logout`)
        }
    })
}
