import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../models/Users'
import { unAuthorisedAccess } from '../system/redux/reducers/auth'
import { navigate } from 'gatsby'

const Logout = ({ dispatch, unAuthorised, user }) => {
    useEffect(() => {
        if ( unAuthorised && user.length ) {
            dispatch(unAuthorisedAccess())
        }
        logoutUser(dispatch)
        navigate(`/`)
    }, [user])
    return null
}

function mapStateToProps(state) {
    return {
        user: state.Auth.user,
    };
}
export default connect(mapStateToProps)(Logout)
